import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import ProjectMenu from "./ProjectMenu/ProjectMenu";
import {
  selectSideListItem,
  setProjectDetails,
  selectSideListTab,
} from "../../actions/projectsActions";
import ProjectInfo from "./ProjectInfo";
import JobList from "./JobList/JobList";
import SideList from "./SideList/SideList";
import { API } from "aws-amplify";
import algoliasearch from "algoliasearch";
import config from "../../config";

import PurchaseOrder from "./Documents/ProjectDocuments/PurchaseOrder/PurchaseOrder";
import QuoteWrapper from "./Documents/ProjectDocuments/Quote/QuoteWrapper";
import MarginReport from "./Documents/ProjectDocuments/MarginReport/MarginReport";
import { MDBBtn } from "mdbreact";
import AddJob from "./PopUps/AddJob";
import EditProject from "./PopUps/EditProject";
import DeleteProject from "./PopUps/DeleteProject";
import { setAlgoliaLoaderState, setLoaderState } from "../../actions/loaderActions";
import { setRefresh } from "../../actions/algoliaActions";
import DuplicateJob from "./PopUps/DuplicateJob";
import TwoStageFab from "../Generic/Fab/TwoStageFab";

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { add: false };
    if (
      this.props.projectDetails === undefined ||
      this.props.projectDetails === {} ||
      this.props.projectDetails.objectId !== this.props.match.params.id
    ) {
      this.getLinkProjectDetails(this.props.match.params.id);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.projectDetails !== nextProps.projectDetails) {
      return true;
    } else {
      return false;
    }
  }

  onJobAddClick = () => {
    this.props.history.push("/projects/" + this.props.match.params.id + "/add");
  };

  onJobDuplicateClick = () => {
    this.props.history.push("/projects/" + this.props.match.params.id + "/duplicate-job");
  };

  handleDuplicateProject = async () => {
    this.props.dispatch(setAlgoliaLoaderState(true));
    this.props.dispatch(setLoaderState(true));
    const projectToDuplicate = this.props.projectDetails;
    const duplicateProjectBody = {
      details: {
        ...projectToDuplicate.details,
        name: projectToDuplicate.details.name + "(Duplicated)",
      },
    };
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.handleDuplicateProject();
      }, 500);
    } else {
      try {
        const projectResponse = await API.post(
          "telishadeAPI",
          "/project/" + projectToDuplicate.type,
          { body: duplicateProjectBody }
        );
        const newProjectId = projectResponse.id;
        const client = await algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const index = await client.initIndex(`${config.algolia.prefix}projects_jobs_number_asc`);
        const OldJobs = await index.search({
          query: "",
          filters:
            "isDeleted:false AND projectId:" +
            (projectToDuplicate.id || projectToDuplicate.objectID),
          hitsPerPage: 1000,
        });
        const curtainProductId = await API.get("telishadeAPI", "/product/curtain");
        const blindProductId = await API.get("telishadeAPI", "/product/blind");
        let curtainsToDuplicate = [];
        let blindsToDuplicate = [];
        let jobIdMap = {};
        //Way Simpler Function that it was before
        for (let i = 0; i < OldJobs.hits.length; i++) {
          let jobPostBody = { details: { ...OldJobs.hits[i].details } };
          const jobResponse = await API.post(
            "telishadeAPI",
            "/project/" + projectResponse.type + "/" + newProjectId,
            {
              body: jobPostBody,
            }
          );
          jobIdMap = { ...jobIdMap, [OldJobs.hits[i].objectID]: jobResponse.id };
          let OldJobItemBlinds = await API.get(
            "telishadeAPI",
            `/project/${projectToDuplicate.type}/${
              projectToDuplicate.id || projectToDuplicate.objectID
            }/job/${OldJobs.hits[i].objectID}/items/blind`
          );
          blindsToDuplicate.push(...OldJobItemBlinds.items);
          let OldJobItemCurtain = await API.get(
            "telishadeAPI",
            `/project/${projectToDuplicate.type}/${
              projectToDuplicate.id || projectToDuplicate.objectID
            }/job/${OldJobs.hits[i].objectID}/items/curtain`
          );
          curtainsToDuplicate.push(...OldJobItemCurtain.items);
        }
        let blindPromises = [];
        let curtainPromises = [];
        let chunkMultiples = 50;
        for (let i = 0; i < blindsToDuplicate.length; i++) {
          let JobItemPostBody = {
            productId: blindProductId.id,
            details: { ...blindsToDuplicate[i].details },
          };
          blindPromises.push(
            API.post(
              "telishadeAPI",
              `/project/${projectToDuplicate.type}/${newProjectId}/job/${
                jobIdMap[blindsToDuplicate[i].projectJobId]
              }/item/${blindProductId.type}`,
              { body: JobItemPostBody }
            )
          );
          if ((i + 1) % chunkMultiples === 0) {
            await Promise.all(blindPromises);
          }
        }
        for (let i = 0; i < curtainsToDuplicate.length; i++) {
          let JobItemPostBody = {
            productId: curtainProductId.id,
            details: { ...curtainsToDuplicate[i].details },
          };
          curtainPromises.push(
            API.post(
              "telishadeAPI",
              `/project/${projectToDuplicate.type}/${newProjectId}/job/${
                jobIdMap[curtainsToDuplicate[i].projectJobId]
              }/item/${curtainProductId.type}`,
              { body: JobItemPostBody }
            )
          );
          if ((i + 1) % chunkMultiples === 0) {
            await Promise.all(curtainPromises);
          }
        }
        await Promise.all(blindPromises);
        await Promise.all(curtainPromises);
      } catch (e) {
        console.error(e);
      } finally {
        this.props.dispatch(setLoaderState(false));
        setTimeout(() => {
          this.props.dispatch(setRefresh(true));
          this.props.dispatch(setRefresh(false));
          this.props.dispatch(setAlgoliaLoaderState(false));
        }, 0);
      }
    }
  };

  timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getProjectDocumentNumberFor(type) {
    if (
      this.props.projectDetails.details !== undefined &&
      "number" in this.props.projectDetails.details
    ) {
      // Special request from Sam: "Have quote numbers start from 2000"
      if (type === "retail-quote") {
        return `QR-${2000 + this.props.projectDetails.details.number}`;
      }

      if (type === "wholesale-quote") {
        return `QW-${2000 + this.props.projectDetails.details.number}`;
      }

      if (type === "purchase-order") {
        return `PO-${2000 + this.props.projectDetails.details.number}`;
      }
    }

    return "TBC";
  }

  componentWillMount() {
    this.props.dispatch(selectSideListItem(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.dispatch(setProjectDetails({}));
  }

  getLinkProjectDetails = async (id) => {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get("telishadeAPI", "/project/commercial/" + id);
      this.props.dispatch(setProjectDetails(response));
      this.props.dispatch(selectSideListTab(response.type));
      this.props.dispatch(setLoaderState(false));
    } catch (e) {
      try {
        const response = await API.get("telishadeAPI", "/project/domestic/" + id);
        this.props.dispatch(setProjectDetails(response));
        this.props.dispatch(selectSideListTab(response.type));
        this.props.dispatch(setLoaderState(false));
      } catch (error) {
        console.error(error);
        console.error(e);
        this.props.dispatch(setLoaderState(false));
      }
    }
  };

  render() {
    const params = this.props.match.params;
    let id = parseInt(params.id, 10);
    if (isNaN(id)) id = 1;
    return (
      <Fragment>
        <Switch>
          <Route exact path="/projects/:id/add" component={AddJob} />
          <Route exact path="/projects/:id/duplicate-job" component={DuplicateJob} />
          <Route
            path="/projects/:id/edit"
            component={() => <EditProject details={this.props.projectDetails} />}
          />
          <Route path="/projects/:id/delete" component={() => <DeleteProject />} />
        </Switch>
        <SideList history={this.props.history} />
        <div className="projectContent">
          <div className="projectHeader">
            <h2>
              {this.props.projectDetails.details !== undefined
                ? this.props.projectDetails.details.name
                : "Nothing Selected"}
            </h2>
            <MDBBtn onClick={this.handleDuplicateProject} color="blue" id="DuplicateProjectBtn">
              Duplicate Project
            </MDBBtn>
          </div>
          <div className="projectInfoContainer">
            <ProjectMenu match={this.props.match} />
            <div className="projectDetailsContainer">
              <Switch>
                <Route
                  exact
                  path="/projects/:id/documents/purchase-order"
                  render={() => (
                    <PurchaseOrder docNumber={this.getProjectDocumentNumberFor("purchase-order")} />
                  )}
                />
                <Route
                  path="/projects/:id/documents/(retail-quote)"
                  render={(routeProps) => (
                    <QuoteWrapper
                      {...routeProps}
                      docNumber={this.getProjectDocumentNumberFor("retail-quote")}
                    />
                  )}
                />
                <Route
                  path="/projects/:id/documents/(wholesale-quote)"
                  render={(routeProps) => (
                    <QuoteWrapper
                      {...routeProps}
                      docNumber={this.getProjectDocumentNumberFor("wholesale-quote")}
                    />
                  )}
                />
                <Route
                  exact
                  path="/projects/:id/documents/margin-report"
                  component={MarginReport}
                />
                <Route
                  path="/projects/:id"
                  render={() => (
                    <Fragment>
                      <ProjectInfo project={this.props.projectDetails} />
                      <JobList />
                      <TwoStageFab
                        handleButton1={this.onJobDuplicateClick}
                        handleButton2={this.onJobAddClick}
                      />
                    </Fragment>
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.projects, AlgoliaKey: state.key };
};

export default withRouter(connect(mapStateToProps)(ProjectDetails));
